import React, { useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
import axios from "axios";
import SuccessPaymentModal from "./SuccessPaymentModal";


const Paymentmodal = ({ isOpen, onClose, appointmentId }) => {
  const fixedAmount = 50;
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const agent = new https.Agent({
  //   rejectUnauthorized: false,  // Ignore invalid SSL certificates
  // });
  function startPay() {
    window.handleinitDataCallback = function () {
      window.location.href = window.location.origin;
    };
    if (!fixedAmount) {
      return;
    }
  
    //let loading = weui.loading("loading", {});
    setIsLoading(true);
    window
      .fetch("https://qetero.com/api/v1/payments", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          appointment_id: appointmentId,
          type: "service Fee",
          amount: fixedAmount.toString(),
          title: "serviceFee_" + fixedAmount,
          paymentType : "InApp"
        }),
      })
      .then((res) => {
        res
          .text()
          .then((rawRequest) => {
            //   console.log(rawRequest.trim());
            let obj = JSON.stringify({
              functionName: "js_fun_start_pay",
              params: {
                rawRequest: rawRequest.trim(),
                functionCallBackName: "handleinitDataCallback",
              },
            });
  
            if (typeof rawRequest === undefined || rawRequest === null) return;
            if (window.consumerapp === undefined || window.consumerapp === null) {
              console.log("This is not opened in app!");
              return;
            }
            window.consumerapp.evaluate(obj);
          })
          .catch((error) => {
            console.log("error occur", error);
          })
          .finally(() => {});
      })
      .finally(() => {
        //loading.hide();
        setIsLoading(false);
      });
  }

  function useAnchorOpen(link) {
    let anchorEle = document.createElement("a");
    anchorEle.setAttribute("href", link);
    anchorEle.setAttribute("target", "_blank");
    anchorEle.setAttribute("rel", "external");
    anchorEle.style.display = "none";
    anchorEle.click();
  }

  const handlePay = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(`https://qetero.com/api/v1/payments`, {
        appointment_id: appointmentId,
        type: "service Fee",
        amount: fixedAmount.toString(),
        title: "serviceFee_" + fixedAmount,
        paymentType : "C2B"
      });
      console.log("payment created:", response.data);
      const assembledUrl = response.data.url;
      useAnchorOpen(assembledUrl.trim());
      //setPaymentSuccess(true);
    } catch (error) {
      console.error("Error creating payment:", error.message);
    }
  };
  const pay = async () => {
    if (window.consumerapp === undefined || window.consumerapp === null) {
      console.log("This is not opened in app!");
      handlePay();
    }
    else {
      startPay();
    }
  }
  const handleSuccessModalClose = () => {
    setPaymentSuccess(false);
    onClose();
  };
  if (!isOpen) return null;
  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-indigo-900 bg-opacity-50 backdrop-blur-sm">
        <div className="bg-white rounded-lg shadow-lg p-8 w-full max-w-md">
          <div className="flex justify-end mb-4">
            <button onClick={onClose}>
              <CloseOutlined className="text-gray-500 hover:text-gray-700" />
            </button>
          </div>
          <h2 className="text-2xl font-bold mb-4">Pay Your Service Fee</h2>
          <p className="text-lg mb-4">Amount: {fixedAmount}</p>
          <div className="flex justify-center mt-4">
            <button
              className="bg-indigo-600 w-full text-white font-bold py-2 px-4 rounded"
              onClick={pay}
            >
              {isLoading ? "Processing..." : "Pay"}
            </button>
          </div>
        </div>
      </div>
      <SuccessPaymentModal
        isOpen={paymentSuccess}
        onClose={handleSuccessModalClose}
      />
    </>
  );
};

export default Paymentmodal;
