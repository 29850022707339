import React, { useState } from "react";
import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css';
import { useTranslation } from 'react-i18next';

const DateSelection = ({ selectedDate, setSelectedDate, nextStep, backStep }) => {
  const { t } = useTranslation();
  const [tempDate, setTempDate] = useState(null); // State to hold the temporarily selected date

  const handleDateClick = (date) => {
    setTempDate(date); // Set the temporarily selected date
  };

  const handleNextClick = () => {
    if (tempDate) {
      setSelectedDate(tempDate); // Set the confirmed date
      nextStep(); // Move to the next step
    }
  };

  return (
    <div className="bg-white border py-4 mt-4 rounded-lg border-gray-300 text-gray-900 text-sm "
>
      <Calendar
        onClickDay={handleDateClick}
        value={tempDate || selectedDate} // Show temp date if it exists, otherwise show selectedDate
        minDate={new Date()}
        tileClassName={"bg-white border border-gray-300 text-gray-800 text-sm rounded-md focus:ring-blue-400 focus:border-blue-400 block w-full pl-12 p-4 shadow-md transition-all duration-300 ease-in-out"}
        
        
      />
      <div className="flex w-full justify-around mt-4">
        <button onClick={backStep}           className="bg-gray-400 text-white px-6 py-2 rounded-lg shadow-md hover:bg-gray-500 transition duration-300 ease-in-out"
        >
          {t('back')}
        </button>
        <button
          onClick={handleNextClick}
          className="bg-blue-600 text-white px-6 py-2 rounded-lg shadow-md hover:bg-blue-500 transition duration-300 ease-in-out"
          disabled={!tempDate} // Enable button only if tempDate is selected
        >
          {t('next')}
        </button>
      </div>
    </div>
  );
};

export default DateSelection;
