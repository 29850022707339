import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { QRCodeCanvas } from "qrcode.react"; // Use QRCodeCanvas instead of QRCode
import { useTranslation } from 'react-i18next'; // Import useTranslation
import { AuthContext } from '../../contexts/authContext';

const SuccessPaymentModal = ({ isOpen, onClose, data }) => {
  const navigate = useNavigate();
  const { t } = useTranslation(); // Initialize useTranslation
  const { user } = useContext(AuthContext);
  


  if (!isOpen) return null;

  // Helper function to format the appointment time
  const formatAppointmentTime = (appointmentTime) => {
    // Create a new Date object from the provided appointment time
    const date = new Date(appointmentTime);
    
    // Deduct 6 hours
    date.setHours(date.getHours() - 6);
    
    // Get the date parts
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    // Return formatted string
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const handleClose = () => {
    navigate('/bookingHistory');
    onClose(); // Optionally call onClose if you want to perform additional actions
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-indigo-900 bg-opacity-50 backdrop-blur-sm">
      <div className="bg-white rounded-lg shadow-lg p-8 w-full max-w-md">
        <img src="/icon.png" alt="Qetero" className="w-8 h-8 mb-2" />
        <h2 className="text-2xl text-center font-bold mb-4">{t('appointmentBooked')}</h2> {/* Translated */}
        {/* Receipt Section */}
        <div className="border-t border-b py-4 mb-4">
          <h3 className="font-bold text-lg mb-2">{t('receipt')}</h3> {/* Translated */}
          <div className="text-sm">
            <p><strong>{t('booked_by')}:</strong> {user.user.firstname} {user.user.lastname}</p> {/* Translated */}
            <p><strong>{t('branch')}:</strong> {data.branch}</p> {/* Translated */}
            <p><strong>{t('service')}:</strong> {data.service}</p> {/* Translated */}
            <p><strong>{t('appointmentTime')}:</strong> {formatAppointmentTime(data.appointment_time)}</p> {/* Translated */}
          </div>
        </div>

        {/* QR Code Section */}
        <div className="flex justify-center mb-4">
          <QRCodeCanvas value={data.cnr} size={150} />
        </div>
        <p className="text-sm text-center">{t('scanQRCode')}</p> {/* Translated */}

        {/* Close Button */}
        <div className="flex justify-center mt-4">
          <button
            className="bg-indigo-600 w-full text-white font-bold py-2 px-4 rounded"
            onClick={handleClose}
          >
            {t('close')} {/* Translated */}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SuccessPaymentModal;
