import React, { useState } from "react";
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import axios from "axios";
import { FaBuildingColumns  } from "react-icons/fa6";


const fetchBranches = async () => {
  const response = await axios.get("https://qetero.com/api/v1/branches");
  return response.data.data.map((branch) => ({
    id: branch.id,
    name: branch.name,
    address: branch?.location?.landmark,
    active: branch.active,
  }));
};

const BranchSelection = ({ selectedBranch, setSelectedBranch, setSelectedBranchName, nextStep,setSelectedAddress }) => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState('');
  const [showAll, setShowAll] = useState(false);
  
  const {
    data: locationOptions,
    isLoading: branchesLoading,
    error: branchesError,
  } = useQuery({
    queryKey: 'branches',
    queryFn: fetchBranches,
  });

  // Filter and sort branches by active status
  const filteredBranches = locationOptions
    ?.filter(branch => branch.name.toLowerCase().includes(searchQuery.toLowerCase()))
    .sort((a, b) => b.active - a.active);  // Sort active branches first

  const displayedBranches = showAll ? filteredBranches : filteredBranches?.slice(0, 17);

  const handleBranchSelection = (branchId, branchName, active,location) => {
    if (active) {
      setSelectedBranch(branchId);
      setSelectedBranchName(branchName);  // Set the branch name in the parent
      setSelectedAddress(location)
      console.log('Selected Branch Name:', branchName);
      nextStep();
    }
  };

  return (
    <div className=" ">
      {/* Search Bar */}
      <div className=" flex flex-col justify-between bg-blue-900 w-screen px-8 py-4 rounded-b-2xl shadow-lg">
        <input
          type="text"
          placeholder={t('searchBranch')}
          className="bg-white border border-gray-300 text-gray-800 text-sm rounded-full focus:ring-blue-400 focus:border-blue-400 block w-full pl-12 p-3 shadow-md transition-all duration-300 ease-in-out"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />

      </div>
      <div className="flex flex-col justify-between bg-gray-100 p-2">
      <div className=" flex px-2 justify-center align-middle text-center bg-white border-gray-200 border rounded-xl py-2">
        <img src="/Dars-Logo.png" alt="Qetero" className="w-12 h-12  contain-content" />
        <h2  className="self-center font-normal ml-2  line-clamp-2  text-sm text-gray-800 ">{t('Welcome_to_Documents_Authentication_and_Registration_Service_(DARS)_at_Qetero.com')}</h2>
      </div>
      <div className="border rounded-xl  bg-white border-gray-200  p-2 mt-2 ">
        {branchesLoading ? (
          <div className="grid grid-cols-3 bg-white md:grid-cols-4 lg:grid-cols-6 gap-4">
            {Array.from({ length: 9 }).map((_, index) => (
              <div key={index} className="p-4 border border-gray-300 bg-white rounded-lg shadow-sm animate-pulse">
                <div className="h-6 bg-gray-200 rounded-md mb-4"></div>
                <div className="h-4 bg-gray-200 rounded-md"></div>
              </div>
            ))}
          </div>
        ) : branchesError ? (
          <p className="text-red-500 font-semibold text-center">Error loading branches</p>
        ) : (
          <div className="grid  grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4">
            {displayedBranches?.map((branch) => (
              <div
                key={branch.id}
                className={`p-2 flex flex-col md:p-2 border rounded-lg shadow-md transition-all transform hover:scale-105  
                ${branch.active ? ' bg-blue-300/5 hover:bg-blue-200 border cursor-pointer' : 'bg-gray-100 text-gray-500 opacity-50 cursor-not-allowed'} 
                ${selectedBranch === branch.id && branch.active ? 'border-blue-600' : 'border-gray-300'}`}
                onClick={() => handleBranchSelection(branch.id, branch.name, branch.active,branch?.address)}
              >
                <FaBuildingColumns  className="w-14 h-14 self-center  text-blue-800 " />
                <div className="items-center space-y-2">
                  <h3 className={`text-sm md:text-lg mt-2 line-clamp-1 text-gray-700 font-semibold text-center`}>
                    {t(branch.name)}
                  </h3>
                  {/* <h4 className="text-sm font-normal text-gray-700 text-center line-clamp-1">
                    {t(branch.address)}
                  </h4> */}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      </div>
    </div>
  );
};

export default BranchSelection;
