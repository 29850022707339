import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from '../../Components/Header/QeteroHeader';
import { jwtDecode } from "jwt-decode";

const BookingHistory = () => {
  const [bookingHistory, setBookingHistory] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [userId, setUserId] = useState("");
  const [loading, setLoading] = useState(false); // Loading state

  const decodeToken = () => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        console.log(decodedToken, "Decoded Token");
        const userIdFromToken = decodedToken?.data?.user?.id;
        setUserId(userIdFromToken);
        console.log(userIdFromToken, "User ID extracted from token");
      } catch (error) {
        console.error("Failed to decode token.", error);
      }
    }
  };

  const fetchAppointments = async (page = 1, limit = 10) => {
    setLoading(true); // Start loading
    try {
      const token = localStorage.getItem("token");
      console.log(userId, "Here We go");

      const response = await axios.get(
        `https://qetero.com/api/v1/appointments/findAppointmentsByUser?created_by=${userId}&page=${page}&limit=${limit}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data?.data?.data) {
        console.log(response.data?.data?.data, 'Date HEre');
        
        setBookingHistory(response.data.data.data);
        setTotalItems(Number(response.data.data.totalItems));
      }
    } catch (error) {
      console.error("Fetching appointments failed.", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    decodeToken(); // Decode the token on component mount
  }, []);

  useEffect(() => {
    if (userId) {
      fetchAppointments(currentPage, pageSize);
    }
  }, [currentPage, pageSize, userId]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setCurrentPage(1); // Reset to first page when page size changes
  };

  const formatDate = (date) => {
    if (!date) return '';
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    return new Date(date).toLocaleString("en-US", options);
  };

  return (
    <div>
      <Header />
      <h2 className="font-bold ml-16 text-xl mt-12">My Bookings</h2>

      {/* Table Container */}
      <div className=" mt-12 overflow-x-auto">
        {/* Table */}
        <table className="min-w-full divide-y divide-gray-200">
          {/* Table Head */}
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                CNR
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Service
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Status
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Booked By
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Date/Time
              </th>
            </tr>
          </thead>

          {/* Table Body */}
          <tbody className="bg-white divide-y divide-gray-200">
            {loading && (
              // Loading Skeleton
              <tr>
                <td colSpan="5" className="px-6 py-4 whitespace-nowrap">
                  <div className="animate-pulse space-y-4">
                    <div className="h-4 bg-gray-200 rounded"></div>
                    <div className="h-4 bg-gray-200 rounded w-1/2"></div>
                  </div>
                </td>
              </tr>
            )}

            {!loading && bookingHistory.length === 0 && (
              // No Data Found
              <tr>
                <td colSpan="5" className="px-6 py-4 whitespace-nowrap">
                  No bookings found.
                </td>
              </tr>
            )}

            {!loading &&
              bookingHistory.map((booking) => (
                <tr key={booking.appointment_id}>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {booking.cnr}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {booking.service_name}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {booking.status}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {booking.firstname}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {formatDate(booking.appointment_time)} {/* Format the appointment time */}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="flex items-center justify-between px-4 py-3 bg-white border-t border-gray-200 sm:px-6">
        <div className="flex-1 flex justify-between sm:hidden">
          <a
            href="#"
            className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            Previous
          </a>
          <a
            href="#"
            className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            Next
          </a>
        </div>
        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
          <div>
            <p className="text-sm text-gray-700">
              Showing{" "}
              <span className="font-medium">
                {(currentPage - 1) * pageSize + 1}
              </span>{" "}
              to{" "}
              <span className="font-medium">
                {Math.min(currentPage * pageSize, totalItems)}
              </span>{" "}
              of <span className="font-medium">{totalItems}</span> results
            </p>
          </div>
          <div>
            <nav
              className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
              aria-label="Pagination"
            >
              <a
                href="#"
                className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <span className="sr-only">Previous</span>
                {/* Previous Page Icon */}
              </a>

              {/* Pagination Number Buttons */}
              {Array.from({
                length: Math.ceil(totalItems / pageSize),
              }).map((_, index) => (
                <a
                  key={index}
                  href="#"
                  className={`relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium ${
                    currentPage === index + 1
                      ? "text-blue-600"
                      : "text-gray-700 hover:bg-gray-50"
                  }`}
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </a>
              ))}

              <a
                href="#"
                className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === Math.ceil(totalItems / pageSize)}
              >
                <span className="sr-only">Next</span>
                {/* Next Page Icon */}
              </a>
            </nav>
          </div>
          {/* Page Size Selector */}
          <div className="relative inline-block text-left">
            <select
              value={pageSize}
              onChange={(e) => handlePageSizeChange(Number(e.target.value))}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            >
              {[10, 20, 30].map((size) => (
                <option key={size} value={size}>
                  Show {size}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingHistory;
