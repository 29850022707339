import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { jwtDecode } from "jwt-decode";
import "../../index.css";
import { FaBars, FaTimes } from "react-icons/fa";
import { AuthContext } from "../../contexts/authContext";

function Header() {
  const location = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { user, setUser, setToken } = useContext(AuthContext);

  const linkClasses = (path) => {
    const isActive = location.pathname === path;
    return isActive
      ? "text-black font-bold text-base my-2"
      : "hover:text-black text-base my-2 transition-colors duration-200 ease-in-out";
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    setUser(null);
    setToken(null);
    navigate("/Login");
  };

  const handleNavigation = (path) => {
    const token = localStorage.getItem("token");
    if (path === "/appointment" && !token) {
      navigate("/Login");
    } else {
      navigate(path);
    }
    setMenuOpen(false);
  };

  const handleLanguageChange = (event) => {
    const selectedLanguage = event.target.value;
    localStorage.setItem("language", selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
  };

  return (
    <header className="top-0 left-0 w-full bg-blue-900 z-50">
      <div className="flex justify-between items-center  mx-auto px-4 py-2">
        {/* Logo */}
        <div className="flex items-center" >
          <img
            loading="lazy"
            src="./qetero_logo.svg"
            className="w-10 h-10"
            alt="DARS Logo"
          />
        </div>

        {/* Space filler */}
        <div className="flex-grow"></div>

        {/* Wrapper for Desktop Menu and Language Selector */}
        <div className="flex items-center space-x-4">
          {/* Desktop Menu */}
          <nav className="hidden md:flex text-white items-center space-x-4">
            <button onClick={() => handleNavigation("/")} className={linkClasses("/")}>
              {t("home")}
            </button>
            <button onClick={() => handleNavigation("/aboutus")} className={linkClasses("/aboutus")}>
              {t("aboutUs")}
            </button>
            <button onClick={() => handleNavigation("/contactus")} className={linkClasses("/contactus")}>
              {t("contactUs")}
            </button>
            {user && (
              <>
                <Link to="/Profile" className={linkClasses("/Profile")}>
                  {t("profile")}
                </Link>
                <Link to="/BookingHistory" className={linkClasses("/BookingHistory")}>
                  {t("bookingHistory")}
                </Link>
              </>
            )}
          </nav>

          <div className="hidden md:flex gap-5 items-center">
            {user ? (
              <div className="relative">
                <div
                  className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-blue-200 rounded-full hover:border-spacing-1"
                  onClick={toggleDropdown}
                >
                  <a className="font-medium text-gray-600">
                    {user?.firstname?.charAt(0)?.toUpperCase()}
                    {user?.lastname?.charAt(0)?.toUpperCase()}
                  </a>
                </div>
                {dropdownOpen && (
                  <div className="absolute right-0 mt-2 bg-white rounded-md shadow-xl z-1000">
                    <div className="z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44">
                      <div className="px-4 py-3 text-sm text-gray-900">
                        <div>
                          {user?.firstname} {user?.lastname}
                        </div>
                        <div className="font-medium truncate">{user?.mobile}</div>
                      </div>
                      <div className="py-1">
                        <a
                          href="#"
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          onClick={handleLogout}
                        >
                          {t("logout")}
                        </a>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <button
                className="bg-pink-700 text-white rounded-md px-4 py-2 hover:bg-pink-600 transition-colors duration-200 ease-in-out"
                onClick={() => navigate("/Login")}
              >
                {t("login")}
              </button>
            )}
          </div>

          {/* Language Selector */}
          <select
            className="p-2 border border-pink-800 rounded "
            value={i18n.language}
            onChange={handleLanguageChange}
          >
            <option value="en">English</option>
            <option value="am">አማርኛ</option>
          </select>

          {/* Menu Toggle Button (Mobile) */}
          <div className="md:hidden flex items-center">
            <button onClick={toggleMenu} className="text-2xl text-white">
              {menuOpen ? <FaTimes /> : <FaBars />}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Drawer Menu */}
      {menuOpen && (
        <div className="fixed inset-0 z-50 flex">
          {/* Drawer */}
          <div className="bg-blue-900 shadow-md w-64 p-4 flex flex-col space-y-4">
            <img
              loading="lazy"
              src="./icon.png"
              className="w-10 h-10 mb-4"
              alt="DARS Logo"
            />
            <button
              className="text-left w-full text-white text-base hover:text-black transition-colors duration-200 ease-in-out"
              onClick={() => handleNavigation("/")}
            >
              {t("home")}
            </button>
            <button
              className="text-left w-full text-white text-base hover:text-black transition-colors duration-200 ease-in-out"
              onClick={() => handleNavigation("/aboutus")}
            >
              {t("aboutUs")}
            </button>
            <button
              className="text-left text-white w-full text-base hover:text-black transition-colors duration-200 ease-in-out"
              onClick={() => handleNavigation("/contactus")}
            >
              {t("contactUs")}
            </button>
            {user ? (
              <>
                <button
                  className="text-left w-full text-white text-base hover:text-black transition-colors duration-200 ease-in-out"
                  onClick={() => handleNavigation("/Profile")}
                >
                  {t("profile")}
                </button>
                <button
                  className="text-left w-full text-white text-base hover:text-black transition-colors duration-200 ease-in-out"
                  onClick={() => handleNavigation("/BookingHistory")}
                >
                  {t("bookingHistory")}
                </button>
              </>
            ) : (
              <button
                className="text-left w-full text-white text-base hover:text-black transition-colors duration-200 ease-in-out"
                onClick={() => handleNavigation("/login")}
              >
                {t("login")}
              </button>
            )}
          </div>

          {/* Close Drawer when clicking outside */}
          <div
            className="flex-1"
            onClick={() => setMenuOpen(false)}
          />
        </div>
      )}
    </header>
  );
}

export default Header;
