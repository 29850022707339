import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        home: "Home",
        aboutUs: "About Us",
        contactUs: "Contact Us",
        profile: "Profile",
        bookingHistory: "Booking History",
        login: "Login",
        logout: "Sign out",
        welcome_to_qetero: "Welcome to Qetero",
        "Welcome_to_Documents_Authentication_and_Registration_Service_(DARS)_at_Qetero.com":"Welcome to Documents Authentication and Registration Service (DARS)",
        selectLocation: "Select Location",
        selectService: "Select Service",
        selectDate: "Select Date",
        AvailableTimes: "Available Times",
        Fetchingavailabletimeslots: "Fetching available time slots...",
        save_time_and_book_now: "Save Time & Book Now",
        confirm_appointment: "Confirm Appointment",
        service_type: "Service Type",
        scheduled_for: "Scheduled For",
        booked_by: "Booked By",
        location: "Location",
        payment_summary: "Payment Summary",
        total_appointment_fee: "Total Appointment Fee",
        pay_now: "Pay Now",
        request_cancellation: "Request Cancellation",
        dars_application_no: "DARS Application No",
        selectBranch: "Select Branch",
        selectService: "Select Service",
        selectDate: "Select Date",
        selectTimeSlot: "Select Time Slot",
        next: "Next",
        bookAppointment: "Book Appointment",
        cancel: "Cancel",
        morning: "Morning",
        afternoon: "Afternoon",
        slot: "slot",
        slots: "slots",
        branch: "Branch",
        service: "Service",
        date: "Date",
        time: "Time",
        select: "Select",
        "selam":"Selam",
          "whoWeAre": "Who We Are",
          "aboutUsDescription": "Welcome to Qetero, the ultimate online booking system designed to streamline your scheduling needs. Our platform is built with efficiency, ease of use, and customer satisfaction in mind, ensuring a smooth experience for both service providers and clients.",
          "mission": "Our Mission",
          "missionDescription": "At Qetero, our mission is to provide a seamless and efficient online booking experience, allowing users to manage their schedules effortlessly while maximizing convenience and accessibility.",
          "vision": "Our Vision",
          "visionDescription": "Our vision is to become a leader in online booking solutions, recognized for our innovation, reliability, and exceptional customer service. We aim to revolutionize the way individuals and businesses schedule their appointments.",
          "coreValues": "Core Values",
          "coreValuesDescription": "We prioritize the needs of our customers, fostering a culture of reliability, integrity, and continuous improvement. Our commitment to excellence drives us to innovate and adapt to the evolving demands of the industry.",              
        searchService: "Search Service",
        searchBranch: "Search Branch",
        darsBranches: "DARS branches",
        showMore: 'Show More',
        showLess: 'Show Less',
        select: "Select",
        "Megenagna (Branch 3)": "Megenagna (Branch 3)",
        "Meskel Square":"Meskel Square",
        "Bole Bulala (Branch 16)":"Bole Bulala (Branch 16)",
        "Lebu Maberat Haile (Branch 15)":"Lebu Maberat Haile (Branch 15)",
        "Jemo 1 Condominium (Branch 14)":"Jemo 1 Condominium (Branch 14)",
        "Summit Adebabay (Branch 13)":"Summit Adebabay (Branch 13)",
        "Bole Medhanialem (Branch 12)":"Bole Medhanialem (Branch 12)",
        "Qera (Branch 6)":"Qera (Branch 6)",
        "Kasanchis - Bambis (Branch 8)":"Kasanchis - Bambis (Branch 8)",
        "Kality":"Kality",
        "Ayer Tena Adebabay (Branch 6)":"Ayer Tena Adebabay (Branch 6)",
        "4 Kilo (Branch 4)":"4 Kilo (Branch 4)",
        "6 Kilo (Branch 16)": "6 Kilo (Branch 16)",
        "Merkato- Ihel Berenda":"Merkato- Ihel Berenda",
        "Global Lancha (Branch 1)":"Global Lancha (Branch 1)",
        "Dire Dawa":"Dire Dawa",
        "Ledeta":"Ledeta",
        "Plc Services":"Plc Services",
        "Sales Services": "Sales Services",
        "Gift Services": "Gift Services",
        "Rental Services":"Rental Services",
        "Loan Services":"Loan Services",
        "Representation Services":"Representation Services",
        "enjoyFreeService": "Skip the long queues and save time with Qetero! Book your appointment for free now before booking fees start soon.",
        "freeForNow": "Free for now!",
        "stayTuned": "Stay tuned for the next appointment.",
        "processing": "Processing...",
        "loading": "Loading...",
        "back":"back",
        "enterPhoneNumber": "Enter your Phone Number",
        "phoneNumber": "Phone Number",
        "sendVerificationCode": "Send Verification Code",
        "sending": "Sending...",
        "verificationCodeSent": "Verification code sent successfully!",
        "book_now": "Book Now",
        "appointmentBooked": "Appointment Booked!",
        "receipt": "Receipt",
        "appointmentTime": "Appointment Time",
        "scanQRCode": "Scan the QR code to access your appointment details.",
        "close": "Close",
        "enterOtp": "Enter OTP",
        "otp": "OTP",
        "verify": "Verify",
        "otpVerified": "OTP verified successfully!",
        "enterPhoneNumber": "Enter your Phone Number",
        "phoneNumber": "Phone Number",
        "sendVerificationCode": "Send Verification Code",
        "sending": "Sending...",
        "verificationCodeSent": "Verification code sent successfully!",
        "enterOtp": "Enter OTP",
        "otp": "OTP",
        "verify": "Verify",
        "otpVerified": "OTP verified successfully!",
        "paymentSuccess": "Payment Successful!",
        "paymentFailed": "Payment Failed!",
        "tryAgain": "Try Again",
        "appointmentCanceled": "Appointment Canceled!",
        "appointmentCanceledSuccessfully": "Appointment Canceled Successfully!",
        "cancelAppointment": "Cancel Appointment",
        "cancel": "Cancel",
        "confirm": "Confirm",
        "downloadReceipt": "Download Receipt",
        "download": "Download",
        "Bole Bulala": "Bole Bulala",
        "Kasanchis - Bambis": "Kasanchis - Bambis",
        "Qera": "Qera",
        "Summit Adebabay": "Summit Adebabay",
        "Ayer Tena Adebabay": "Ayer Tena Adebabay",
        "4 Kilo": "4 Kilo",
        "Lebu Maberat Haile": "Lebu Maberat Haile",
        "Bole Medhanialem": "Bole Medhanialem",
        "Mexico": "Mexico",
        "6 Kilo": "6 Kilo",
        "Global Lancha": "Global Lancha",
        "Merkato- Ihel Berenda": "Merkato- Ihel Berenda",
        "Jemo 1 Condominium": "Jemo 1 Condominium",
        "Kality": "Kality",
        "Megenagna": "Megenagna",
        "Dire Dawa": "Dire Dawa",
        "Meskel Square": "Meskel Square",
        "Chelelek Alsam Tower - 2nd Floor": "Chelelek Alsam Tower - 2nd Floor",
        "Jemo 1": "Jemo 1",
        "Ayer Tena":"Ayer Tena",
        "Bole":"Bole",
        "Summit":"Summit",
        "Kasanchis":"Kasanchis",
        "Merkato":"Merkato",
        "Mesalemia":"Mesalemia",
        "Lebu":"Lebu",
        "Near to Ethio Tebib Hospital":"Near to Ethio Tebib Hospital",
        "Derartu Tower":"Derartu Tower",
        "Baleker Building":"Baleker Building"
      },
    },
    am: {
      translation: {
        home: "ዋና ገጽ",
        aboutUs: "ስለ እኛ",
        contactUs: "እኛን ያግኙ",
        profile: "መገለጫ",
        bookingHistory: "የቀጠሮ ታሪክ",
        login: "ግባ",
        logout: "ውጣ",
        welcome_to_qetero: "እንኳን ወደ ቀጠሮ በደህና መጡ",
        selectLocation: "ቦታ ይምረጡ",
        selectService: "አገልግሎት ይምረጡ",
        selectDate: "ቀን ይምረጡ",
        AvailableTimes: "የሚገኙ ሰዓቶች",
        Fetchingavailabletimeslots: "የሚገኙ ሰዓቶች እየተጫኑ ነው...",
        save_time_and_book_now: "ጊዜዎን ይቆጥቡ እና አሁን ይያዙ",
        confirm_appointment: "ቀጠሮዎን ያረጋግጡ",
        service_type: "የአገልግሎት አይነት",
        scheduled_for: "ለተያዘው",
        booked_by:"የተያዘው በ",
        location: "አድራሻ",
        payment_summary: "የክፍያ ማጠቃለያ",
        total_appointment_fee: "የአጠቃላይ ቀጠሮ ክፍያ",
        pay_now: "አሁን ይክፈሉ",
        request_cancellation: "የመሰረዝ ጥያቄ ያቅርቡ",
        dars_application_no: "የDARS ማመልከቻ ቁጥር",
        selectBranch: "ቅርንጫፍ ይምረጡ",
        selectService: "አገልግሎት ይምረጡ",
        selectDate: "ቀን ይምረጡ",
        selectTimeSlot: "የጊዜ ክፍተት ይምረጡ",
        next: "ቀጣይ",
        bookAppointment: "ቀጠሮ ይያዙ",
        cancel: "ሰርዝ",
        morning: "ጠዋት",
        afternoon: "ከሰዓት",
        slot: "ቦታ",
        slots: "ቦታዎች",
        branch: "ቅርንጫፍ",
        service: "አገልግሎት",
        date: "ቀን",
        time: "ሰዓት",
        select: "ይምረጡ",
        searchService: "አገልግሎት ይፈልጉ",
        searchBranch: "ቅርንጫፍ ይፈልጉ",
        darsBranches: "የDARS ቅርንጫፎች",
        showMore: 'ተጨማሪ አሳይ',
        showLess: 'ያነሰ አሳይ',
        select: "ይምረጡ",
        "selam":"ሰላም",
        "Welcome_to_Documents_Authentication_and_Registration_Service_(DARS)_at_Qetero.com":"እንኳን በደህና መጡወደ የሰነዶች ማረጋገጫ እና ምዝገባ አገልግሎት (DARS)",
        "Megenagna (Branch 3)": "መገናኛ (ቅርንጫፍ 3)",
        "Meskel Square":"መስቀል አደባባይ",
        "Bole Bulala (Branch 16)":"ቦሌ ቡላቡላ (ቅርንጫፍ 16)",
        "Lebu Maberat Haile (Branch 15)":"ሌቡ መብራት ኃይሌ (ቅርንጫፍ 15)",
        "Jemo 1 Condominium (Branch 14)":"ጀሞ 1 ኮንዶሚኒየም (ቅርንጫፍ 14)",
        "Summit Adebabay (Branch 13)":"ሰሚት አደባባይ (ቅርንጫፍ 13)",
        "Bole Medhanialem (Branch 12)":"ቦሌ መድሐኒአለም (ቅርንጫፍ 12)",
        "Qera (Branch 6)":"ቄራ (ቅርንጫፍ 6)",
        "Kasanchis - Bambis (Branch 8)":"ካሳንቺስ - ባምቢስ (ቅርንጫፍ 8)",
        "Kality":"ቃሊቲ",
        "Ayer Tena Adebabay (Branch 6)":"አየር ተና አደባባይ (ቅርንጫፍ 6)",
        "4 Kilo (Branch 4)":"4 ኪሎ (ቅርንጫፍ 4)",
        "6 Kilo (Branch 16)": "6 ኪሎ (ቅርንጫፍ 16)",
        "Merkato- Ihel Berenda":"መርካቶ-እህል በረንዳ",
        "Global Lancha (Branch 1)":"ግሎባል ላንቻ (ቅርንጫፍ 1)",
        "Dire Dawa":"ድሬ ዳዋ",
        "Ledeta":"ልደታ",
        "Plc Services":"የPlc አገልግሎት",
        "Sales Services": "የሻጮች አገልግሎት",
        "Gift Services": "የስጦታ አገልግሎት",
        "Rental Services":"የኪራይ አገልግሎት",
        "Loan Services":"የብድር አገልግሎት",
        "Representation Services":"የውክልና አገልግሎት",
        "enjoyFreeService": "ረጅም መስመሮችን ይዝለሉ እና በቀጠሮ ጊዜዎን ይቆጥቡ! በቅርቡ የመያዣ ክፍያ ከመጀመሩ በፊት አሁን ነፃ ቀጠሮዎን ይያዙ።",
        "freeForNow": "ለአሁን ነፃ!",
        "stayTuned": "ለቀጣዩ ቀጠሮ ይጠብቁ።",
        "processing": "እየተሰራ ነው...",
        "loading": "እየተከናወነ ነው...",
        "back":"ወደ ኋላ",
        "enterPhoneNumber": "የስልክ ቁጥርዎን ያስገቡ",
        "phoneNumber": "የስልክ ቁጥር",
        "sendVerificationCode": "የማረጋገጫ ኮድ ይላኩ",
        "sending": "እየተላከ ነው...",
        "verificationCodeSent": "የማረጋገጫ ኮድ  ተልኳል!",
        book_now: "አሁን ይያዙ",
        "appointmentBooked": "ቀጠሮ ተይዟል!",
        "receipt": "ደረሰኝ",
        "appointmentTime": "የቀጠሮ ሰዓት",
        "scanQRCode": "የQR ኮዱን ለመቃኘት እና የቀጠሮ ዝርዝሮችዎን ለማግኘት ይጠቀሙ።",
        "close": "ዝጋ",
        "enterOtp": "OTP ያስገቡ",
        "otp": "OTP",
        "verify": "ያረጋግጡ",
        "otpVerified": "OTP በተሳካ ሁኔታ ተረጋግጧል!",
        "enterPhoneNumber": "የስልክ ቁጥርዎን ያስገቡ",
        "phoneNumber": "የስልክ ቁጥር",
        "sendVerificationCode": "የማረጋገጫ ኮድ ይላኩ",
        "sending": "እየተላከ ነው...",
        "verificationCodeSent": "የማረጋገጫ ኮድ  ተልኳል!",
        "enterOtp": "OTP ያስገቡ",
        "otp": "OTP",
        "verify": "ያረጋግጡ",
        "otpVerified": "OTP በተሳካ ሁኔታ ተረጋግጧል!",
        "paymentSuccess": "ክፍያ በተሳካ ሁኔታ ተጠናቀቀ!",
        "paymentFailed": "ክፍያ አልተሳካም!",
        "tryAgain": "እንደገና ይሞክሩ",
        "appointmentCanceled": "ቀጠሮ ተሰርዟል!",
        "appointmentCanceledSuccessfully": "ቀጠሮ በተሳካ ሁኔታ ተሰርዟል!",
        "cancelAppointment": "ቀጠሮ ይሰርዙ",
        "cancel": "ሰርዝ",
        "confirm": "አረጋግጥ",
        "downloadReceipt": "ደረሰኝ ያውርዱ",
        "download": "ያውርዱ",
        "downloadReceipt": "ደረሰኝ ያውርዱ",
        "download": "ያውርዱ",
        "Bole Bulala": "ቦሌ ቡላቡላ",
        "Kasanchis - Bambis": "ካሳንቺስ - ባምቢስ",
        "Qera": "ቄራ",
        "Summit Adebabay": "ሰሚት አደባባይ",
        "Ayer Tena Adebabay": "አየር ተና አደባባይ",
        "4 Kilo": "4 ኪሎ",
        "Lebu Maberat Haile": "ሌቡ መብራት ኃይሌ",
        "Bole Medhanialem": "ቦሌ መድሐኒአለም",
        "Mexico": "ሜክሲኮ",
        "6 Kilo": "6 ኪሎ",
        "Global Lancha": "ግሎባል ላንቻ",
        "Merkato- Ihel Berenda": "መርካቶ-እህል በረንዳ",
        "Jemo 1 Condominium": "ጀሞ 1 ኮንዶሚኒየም",
        "Kality": "ቃሊቲ",
        "Megenagna": "መገናኛ",
        "Dire Dawa": "ድሬ ዳዋ",
        "Meskel Square": "መስቀል አደባባይ",
        "Chelelek Alsam Tower - 2nd Floor": "ጨለለቅ አልሳም ታወር - 2ኛ ፎቅ",
        "Jemo 1": "ጀሞ 1",
        "Ayer Tena":"አየር ጤና",
        "Bole":"ቦሌ",
        "Summit":"ሰሚት",
        "Kasanchis":"ካሳንቺስ",
        "Merkato":"መርካቶ",
        "Mesalemia":"መሳለሚያ",
        "Lebu":"ለቡ",
        "Near to Ethio Tebib Hospital":"ከኢትዮ ጤቢብ ሆስፒታል አጠገብ",
        "Derartu Tower":"ደራርቱ ታወር",
        "Baleker Building":"ባለከር ህንፃ"
      },
    },
  },
  lng: localStorage.getItem("language") || "en", // Default language
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
