import React from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { FaClock } from "react-icons/fa";

const convertToEthiopianTime = (time_from) => {
  const [hours, minutes, seconds] = time_from.split(':').map(Number);
  let ethiopianHours = hours + 6;

  if (ethiopianHours >= 24) {
    ethiopianHours -= 24;
  }

  ethiopianHours = ethiopianHours % 12 || 12; // Handle 00:00 as 12:00 AM

  return `${ethiopianHours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
};

const fetchAvailableTimeSlots = async ({ branchId, serviceId, date }) => {
  const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;
  const response = await axios.get(
    `https://qetero.com/api/v1/appointments/availableTimeSlotsByDate?branch_id=${branchId}&branch_service_id=${serviceId}&appointment_date=${formattedDate}`
  );
  return response.data.data.map((timeSlot) => ({
    time: `${timeSlot.time_from} - ${timeSlot.time_to}`,
    remainingSlots: timeSlot.remaining_slots,
    duration_id: timeSlot.duration_id,
    time_from: timeSlot.time_from,
    period: timeSlot.time_from.split(":")[0] < 12 ? "morning" : "afternoon",
  }));
};

const TimeSlotSelection = ({ selectedBranch, selectedService, selectedDate, selectedTimeSlot, setSelectedTimeSlot, addAppointment, isLoading, backStep }) => {
  const { t } = useTranslation();

  const {
    data: availableTimes,
    isLoading: timesLoading,
    error: timesError,
  } = useQuery({
    queryKey: ['availableTimes', selectedBranch, selectedService, selectedDate],
    queryFn: () => fetchAvailableTimeSlots({ branchId: selectedBranch, serviceId: selectedService, date: selectedDate }),
    enabled: !!selectedBranch && !!selectedService && !!selectedDate,
  });
  console.log("time Slot:", availableTimes)
  return (
    <div className="flex flex-col items-center  mt-4">
      {timesLoading ? (
        <div className="grid grid-cols-3 md:grid-cols-6 gap-4 md:gap-6">
          {Array.from({ length: 9 }).map((_, index) => (
            <div key={index} className="p-1 md:p-2 border-2 rounded-lg cursor-pointer border-gray-300">
              <div className=" h-12 w-24  bg-gray-200 rounded-md"></div>
              <div className="h-4 bg-gray-200 rounded-md mt-2"></div>
            </div>
          ))}
        </div>
      ) : timesError ? (
        <div>Error loading times</div>
      ) : (
        <>
      <div className="border rounded-xl  bg-white border-gray-200  p-2 mt-2 ">
      <h2 className="text-center font-bold text-xl ">{t('morning')}</h2>
        <div className="grid grid-cols-3 md:grid-cols-6 gap-4 md:gap-6">
          {availableTimes?.filter(period => period.period === "morning").map((timeSlot, index) => (
                <div
                key={index}
                className={` flex flex-col p-4 border-2 rounded-lg transition-transform transform hover:scale-105 shadow-md cursor-pointer hover:shadow-lg  ${selectedTimeSlot?.time === timeSlot.time ? 'bg-blue-500 border-blue-600 text-white' : 'bg-white border-gray-300 text-gray-800 hover:bg-blue-50'}`}
                onClick={() => setSelectedTimeSlot(timeSlot)}
              >
                  <FaClock  className={`self-center w-6 h-6 ${selectedTimeSlot?.time === timeSlot.time ? 'text-white' : 'text-blue-800'} `} />
                  <div >
               <h3 className="text-center font-semibold text-md ">{convertToEthiopianTime(timeSlot.time_from)}</h3>
               <p className="text-center">{timeSlot.remainingSlots} {t('slots')}</p>
              </div>
              </div>
          ))}
        </div>
        </div>
        <div className="border rounded-xl  bg-white border-gray-200  p-2 mt-2 ">
        <h2 className="text-center  font-bold text-xl ">{t('afternoon')}</h2>
          <div className="grid grid-cols-3 md:grid-cols-6 gap-4 md:gap-6">
            {availableTimes?.filter(period => period.period === "afternoon").map((timeSlot, index) => (
                <div
                  key={index}
                  className={` flex flex-col p-4 border-2 rounded-lg transition-transform transform hover:scale-105 shadow-md cursor-pointer hover:shadow-lg  ${selectedTimeSlot?.time === timeSlot.time ? 'bg-blue-500 border-blue-600 text-white' : 'bg-white border-gray-300 text-gray-800 hover:bg-blue-50'}`}
                  onClick={() => setSelectedTimeSlot(timeSlot)}
                >
                  <FaClock  className={`self-center w-6 h-6 ${selectedTimeSlot?.time === timeSlot.time ? 'text-white' : 'text-blue-800'} `} />
                <div >
                 <h3 className="text-center font-semibold text-md ">{convertToEthiopianTime(timeSlot.time_from)}</h3>
                 <p className="text-center">{timeSlot.remainingSlots} {t('slots')}</p>
                </div>
                </div>
              ))}
              </div>
        </div>

      </>)}
      <div className="flex w-full justify-around mt-4">
        <button onClick={backStep}   className="bg-gray-400 text-white px-6 py-2 rounded-lg shadow-md hover:bg-gray-500 transition duration-300 ease-in-out"
        >
          {t('back')}
        </button>
      <button
        onClick={addAppointment}
        className="bg-blue-600 text-white px-6 py-2 rounded-lg shadow-md hover:bg-blue-700 transition duration-300 ease-in-out"
        disabled={!selectedTimeSlot || isLoading}
      >
        {isLoading ? t('loading') : t('bookAppointment')}
      </button>
      </div>
    </div>
  );
};

export default TimeSlotSelection;
