import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaBuildingColumns, FaGears } from "react-icons/fa6";

const fetchServices = async (branchId) => {
  const response = await axios.get(
    `https://qetero.com/api/v1/branch_services/findServicesByBranchGuest?branch_id=${branchId}`
  );
  return response.data.services.map((service) => ({
    id: service.id,
    name: service.name,
  }));
};

const ServiceSelection = ({
  selectedBranch,
  selectedService,
  setSelectedService,
  selectedAddress,
  nextStep,
  backStep,
  selectedBranchName, // Add selectedBranchName prop
}) => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState("");
  const [showAll, setShowAll] = useState(false);

  const {
    data: services,
    isLoading: servicesLoading,
    error: servicesError,
  } = useQuery({
    queryKey: ["services", selectedBranch],
    queryFn: () => fetchServices(selectedBranch),
    enabled: !!selectedBranch,
  });

  const filteredServices = services?.filter((service) =>
    service.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const displayedServices = showAll
    ? filteredServices
    : filteredServices?.slice(0, 9);

  return (
    <div className=" bg-gray-100">
      <div className="bg-blue-900 w-screen px-8 py-4 rounded-b-2xl shadow-md">
        <input
          type="text"
          placeholder={t("searchService")}
          className="bg-white border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full pl-12 p-3 shadow-md transition duration-300 ease-in-out"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>
      {/* Service List */}
      <div className="flex flex-col justify-start bg-gray-100 p-2">
      <div className=" flex px-2 justify-center align-middle text-center bg-white border-gray-200 border rounded-xl py-2">
        <FaBuildingColumns  className="w-12 h-12 self-center ring-1 rounded-full p-1  text-pink-600 " />
      <div className="flex flex-col justify-start">
        <h2  className="font-semibold ml-2  line-clamp-2  text-lg text-gray-800 ">{selectedBranchName} {t("branch")}</h2>
        <p className="font-normal ml-2  line-clamp-2  text-sm text-gray-800 ">{selectedAddress} {t("branch")}</p>
        </div>
      </div>
      <div className="border rounded-xl  bg-white border-gray-200  p-2 mt-2 ">
        {filteredServices?.length > 6 && (
          <div className="flex justify-end mb-4">
            <button
              onClick={() => setShowAll(!showAll)}
              className="text-blue-600 font-semibold transition duration-300 ease-in-out hover:text-blue-800"
            >
              {showAll ? t("showLess") : t("showMore")}
            </button>
          </div>
        )}

        <div className="grid grid-cols-3 bg-white md:grid-cols-4 lg:grid-cols-6 gap-4">
          {servicesLoading ? (
            Array.from({ length: 9 }).map((_, index) => (
              <div
                key={index}
                className="p-4 flex flex-col items-center justify-center border-2 border-gray-300 bg-white rounded-lg shadow-sm animate-pulse"
              >
                <div className="h-6 w-20 bg-gray-200 rounded-md mb-2"></div>
                <div className="h-4 w-16 bg-gray-200 rounded-md"></div>
              </div>
            ))
          ) : servicesError ? (
            <p className="text-red-500 font-semibold text-center">
              {t("errorLoadingServices")}
            </p>
          ) : (
            displayedServices?.map((service) => (
              <div
                key={service.id}
                className={`p-2 bg-blue-300/5 flex flex-col border rounded-lg transition-transform transform hover:scale-105 shadow-md cursor-pointer hover:shadow-lg ${
                  selectedService === service.id
                    ? "bg-blue-200 border-blue-600 "
                    : "bg-white border-gray-300 text-gray-800 hover:bg-blue-50"
                }`}
                onClick={() => {
                  setSelectedService(service.id);
                  nextStep();
                }}
              >
                <FaGears   className="w-14 h-14 self-center  text-blue-800 " />
                <h3 className="text-md font-semibold line-clamp-1 text-center">
                  {t(service.name)}
                </h3>
              </div>
            ))
          )}
        </div>
      </div>
      {/* Navigation Buttons */}
      <div className="flex justify-between items-center mt-2">
        <button
          onClick={backStep}
          className="bg-gray-400 text-white px-6 py-2 rounded-lg shadow-md hover:bg-gray-500 transition duration-300 ease-in-out"
        >
          {t("back")}
        </button>
      </div>
      </div>
    </div>
  );
};

export default ServiceSelection;
