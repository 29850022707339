import { jwtDecode } from "jwt-decode";
import React, { useContext, useEffect, useState } from "react";
import "react-calendar/dist/Calendar.css";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../Components/Header/QeteroHeader";
import "../../index.css";
import SimpleFooter from "../Footer/SimpleFooter";
import MultiForm from '../MultiForm';
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../contexts/authContext";

function Appointment() {
  const { t } = useTranslation(); // Initialize useTranslation hook for i18n
  const {user} = useContext(AuthContext)
  const [data, setData] = useState({
    date: null,
    time: null,
    location: "",
    service: "",
  });

  const [firstname, setFirstname] = useState("");
  const [id, setId] = useState("");

  const navigate = useNavigate();
  const location = useLocation();

  console.log("user firstname", user?.firstname)

  return (
    <div className="bg-gray-100">
      <div  >
        <Header />
        <div className="flex flex-col px-5 bg-blue-900 text-stone-900 ">
          <div className="w-full text-3xl font-semibold max-md:mt-10 max-md:max-w-full text-white">
            {t("selam")} <span>{user?.user?.firstname }</span> 
          </div>
          <div className="w-full sm:text-xl lg:text-2xl font-normal max-md:max-w-full pb-4 text-white">
          </div>
         
        </div>
      </div>
      <MultiForm />
      <SimpleFooter />
    </div>
  );
}

export default Appointment;