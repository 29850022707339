import React, { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import "../../index.css";
import "react-calendar/dist/Calendar.css";
import Footer from "../Footer/SimpleFooter";
import Header from "../../Components/Header/QeteroHeader";

function Appointment() {
  const [userData, setUserData] = useState({
    fullName: "",
    phoneNumber: "",
    userId: "",
    active: "",
  });

  // Function to decode JWT and set user data
  const decodeToken = () => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const user = decodedToken?.data?.user;
        if (user) {
          setUserData({
            fullName: `${user.firstname} ${user.lastname}`,
            phoneNumber: user.mobile,
            userId: user.id,
            active: user.active,
          });
        }
      } catch (error) {
        console.error("Failed to decode token.", error);
      }
    }
  };

  useEffect(() => {
    decodeToken();
  }, []);

  return (
    <div>
      <Header />
      <div className="flex flex-col items-center px-5 text-stone-900 mt-12">
        {/* Profile Section - Left and Right Layout */}
        <div className="flex w-full max-w-5xl mt-10">
          {/* Left Side - Profile Info */}
          <div className="w-1/3 flex flex-col items-center p-6 border-r">
            <div className="w-32 h-32 bg-gray-300 rounded-full mb-4 flex justify-center items-center">
              {/* Placeholder for Profile Image */}
              <span className="text-2xl text-gray-500">Profile</span>
            </div>
            <div className="text-3xl font-bold text-center">
              {userData.fullName}
            </div>
            <p className="text-lg text-gray-600 mt-2 text-center">
              Your profile details
            </p>
          </div>

          {/* Right Side - Profile Info */}
          <div className="w-2/3 flex flex-col justify-center pl-10">
            <div className="w-full max-w-lg bg-white shadow-md rounded-lg p-8">
              <div className="mb-6">
                <label
                  htmlFor="fullName"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  Full Name:
                </label>
                <input
                  type="text"
                  id="fullName"
                  name="fullName"
                  value={userData.fullName}
                  readOnly
                  className="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-6">
                <label
                  htmlFor="phoneNumber"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  Phone Number:
                </label>
                <input
                  type="tel"
                  id="phoneNumber"
                  name="phoneNumber"
                  value={userData.phoneNumber}
                  readOnly
                  className="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-6">
                <label
                  htmlFor="userId"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  Active Status:
                </label>
                <input
                  type="text"
                  id="userId"
                  name="userId"
                  value={userData.active}
                  readOnly
                  className="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Appointment;
