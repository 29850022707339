import React, { useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
import SuccessPaymentModal from "./SuccessPaymentModal";
import { useTranslation } from 'react-i18next';

const FreeModal = ({ isOpen, onClose, appointmentId ,data}) => {
  const fixedAmount = 50;
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  console.log("appointment data:",data, appointmentId)

  const pay = async () => {
    setIsLoading(true);

    try {
      // Example data to send in the request
      const paymentData = {
        appointment_id: appointmentId,
        type: "free service fee", // Assuming it's a free appointment
        amount: 0, // Set to 0 for a free payment
        status: true, // Payment successful
      };

      // Send the createPayment API request
      const response = await fetch('https://qetero.com/api/v1/payments/createPayment', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(paymentData),
      });

      if (!response.ok) {
        throw new Error('Payment creation failed');
      }

      // Simulate payment success after API response
      setPaymentSuccess(true);
    } catch (error) {
      console.error('Error saving payment:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSuccessModalClose = () => {
    setPaymentSuccess(false);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-indigo-900 bg-opacity-50 backdrop-blur-sm">
        <div className="bg-white rounded-lg shadow-lg p-8 w-full max-w-md">
          <div className="flex justify-end mb-4">
            <button onClick={onClose}>
              <CloseOutlined className="text-gray-500 hover:text-gray-700" />
            </button>
          </div>
          <h2 className="text-2xl font-bold mb-4">{t('freeForNow')}</h2> {/* Translated */}
          <p className="text-lg mb-4">
            {t('enjoyFreeService', { amount: fixedAmount })} {/* Translated with variable */}
          </p>
          <p className="text-lg mb-4">
            {t('stayTuned')} {/* Translated */}
          </p>
          <div className="flex justify-center mt-4">
            <button
              className="bg-indigo-600 w-full text-white font-bold py-2 px-4 rounded"
              onClick={pay}
            >
              {isLoading ? t('processing') : t('bookAppointment')} {/* Translated */}
            </button>
          </div>
        </div>
      </div>
      <SuccessPaymentModal
        data={data}
        isOpen={paymentSuccess}
        onClose={handleSuccessModalClose}
      />
    </>
  );
};

export default FreeModal;
