import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import axios from "axios";
import { AuthContext } from "../../contexts/authContext";
import BranchSelection from "./BranchSelection";
import ServiceSelection from "./ServiceSelection";
import DateSelection from "./DateSelection";
import TimeSlotSelection from "./TimeSlotSelection";

const Booking = () => {
  const { t } = useTranslation();
  const [step, setStep] = useState(1);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [selectedBranchName, setSelectedBranchName] = useState(""); // State to hold the selected branch name
  const [selectedAddress,setSelectedAddress] = useState('')
  const [selectedService, setSelectedService] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { user,token } = useContext(AuthContext);
  const navigate = useNavigate();

  const stepTitles = ['selectBranch', 'selectService', 'selectDate', 'selectTimeSlot'];

  const nextStep = () => {
    if (step === 1 ){
      if(token){
        setStep(2)
        return
      }
      else{
        navigate("/Login")
      }
    }
    if (step === 2 ) setStep(3);
    if (step === 3 ) setStep(4);
  };

  const addAppointment = async () => {
    setIsLoading(true);
    const [hours, minutes] = selectedTimeSlot.time_from.split(":");
    const date = new Date(selectedDate);
    date.setHours(hours);
    date.setMinutes(minutes);

    const appointmentData = {
      appointment_time: date.toISOString(),
      reschedule_count: 0,
      appointment_through: "self",
      branch_service_id: selectedService,
      appointment_date: date.toISOString().split("T")[0],
      appointment_duration_id: selectedTimeSlot.duration_id,
      created_by: user?.user?.id,
    };
    try {
      const response = await axios.post(
        "https://qetero.com/api/v1/appointments/add_appointment",
        appointmentData
      );
      console.log("respne", response.data)
       if (response.data?.status === "success") {
        const serviceName = response.data.data?.data.service_name; 
        const branchName = selectedBranchName; // Use the selected branch name
        const cnr = response.data.data?.data?.cnr
        // Encode the names to handle special characters in the URL
        const encodedServiceName = encodeURIComponent(serviceName);
        const encodedBranchName = encodeURIComponent(branchName);
        navigate(`/Confirmation?service=${encodedServiceName}&cnr=${cnr}&branch=${encodedBranchName}&appointment_time=${date.toISOString()}&id=${response?.data?.data?.data?.id}`);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex justify-center items-center flex-col ">
      <div className="flex bg-blue-900 w-full justify-center  ">
        {stepTitles.map((title, index) => (
          <div key={index} className="flex  items-center mr-4 mb-4">
            <div className={`w-8 h-8 rounded-full flex items-center justify-center ${step >= index + 1 ? "bg-pink-600 text-white" : "bg-gray-200 text-gray-600"} mr-2`}>
              {index + 1}
            </div>
            {step === index + 1 && (
              <span className={`font-medium ${step >= index + 1 ? "text-white" : "text-gray-600"}`}>
                {t(title)}
              </span>
            )}
          </div>
        ))}
      </div>

      {step === 1 && (
        <BranchSelection
          selectedBranch={selectedBranch}
          setSelectedBranch={setSelectedBranch}
          setSelectedBranchName={setSelectedBranchName}  // Pass down the setter for branch name
          nextStep={nextStep}
          setSelectedAddress={setSelectedAddress}
        />
      )}
      {step === 2 && (
        <ServiceSelection
          selectedBranch={selectedBranch}
          selectedBranchName={selectedBranchName} // Pass selectedBranchName to ServiceSelection
          selectedService={selectedService}
          setSelectedService={setSelectedService}
          selectedAddress= {selectedAddress}
          nextStep={nextStep}
          backStep={() => setStep(1)}
        />
      )}
      {step === 3 && <DateSelection selectedDate={selectedDate} setSelectedDate={setSelectedDate} backStep={() => setStep(2)} nextStep={nextStep} />}
      {step === 4 && (
        <TimeSlotSelection
          selectedBranch={selectedBranch}
          selectedService={selectedService}
          selectedDate={selectedDate}
          selectedTimeSlot={selectedTimeSlot}
          setSelectedTimeSlot={setSelectedTimeSlot}
          addAppointment={addAppointment}
          isLoading={isLoading}
          backStep={() => setStep(3)}
        />
      )}
    </div>
  );
};

export default Booking;
